// frontend/src/layouts/DashboardLayout.js
import React from 'react';
import { Outlet } from 'react-router-dom';
import DashboardHeader from '../components/DashboardHeader';

const DashboardLayout = () => {
  return (
    <>
      <DashboardHeader />
      <main className="bg-gray-100 min-h-screen pt-5"> {/* Adjust padding-top as needed */}
        <Outlet />
      </main>
    </>
  );
};

export default DashboardLayout;
