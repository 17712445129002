// frontend/src/components/LogoutButton.js
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext'; // Import AuthContext

const LogoutButton = () => {
  const navigate = useNavigate();
  const { logout } = useContext(AuthContext); // Destructure logout from AuthContext

  const handleLogout = () => {
    // Optionally, inform the backend about the logout
    // axios.post('/api/logout').catch(error => console.error('Logout failed:', error));

    // Call the logout function from AuthContext
    logout();

    // Redirect to the Get Started page after logout
    navigate('/get-started');
  };

  return (
    <button
      onClick={handleLogout}
      className="w-full text-left px-4 py-3 text-sm text-gray-700 hover:bg-gray-100"
      role="menuitem"
    >
      Logout
    </button>
  );
};

export default LogoutButton;
