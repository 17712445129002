import React, { useState, useEffect } from 'react';
import axios from '../utils/axiosConfig'; // Use the configured Axios instance
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaTimes } from 'react-icons/fa';

const MyUploads = () => {
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // Fetch all images uploaded by the user
  const fetchImages = async () => {
    setIsLoading(true);
    try {
      const res = await axios.get('/api/images');
      setImages(res.data.images);
    } catch (err) {
      console.error(err.response?.data);
      toast.error('Failed to fetch images.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchImages();
  }, []);

  // Remove image
  const handleRemoveImage = async (imageId) => {
    try {
      await axios.delete(`/api/images/${imageId}`);
      await fetchImages(); // Refresh the images after deletion
      toast.info('Image removed.');
    } catch (err) {
      console.error(err.response?.data);
      toast.error('Failed to remove image.');
    }
  };

  return (
    <div className="bg-gray-100 min-h-screen pt-20">
      <ToastContainer />
      <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 py-10">
        <h2 className="text-3xl font-semibold mb-4 text-center">My Uploads</h2>
        {isLoading ? (
          <p className="text-center">Loading images...</p>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {images.map((img) => (
              <div
                key={img.id}
                className="relative group border-2 border-dashed border-gray-300 rounded-md overflow-hidden transition transform hover:scale-105"
              >
                <img
                  src={`https://backpwa.icreativeco.com/${img.image_path}`}
                  alt={`Uploaded ${img.id}`}
                  className="object-cover w-full h-60"
                />
                <button
                  onClick={() => handleRemoveImage(img.id)}
                  className="absolute top-2 right-2 bg-red-600 text-white rounded-full p-1 hover:bg-red-700 transition duration-300"
                  aria-label="Remove Image"
                >
                  <FaTimes className="h-4 w-4" />
                </button>
              </div>
            ))}
            {images.length === 0 && (
              <p className="text-center text-gray-500">No images uploaded yet.</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default MyUploads;
