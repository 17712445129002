// frontend/src/pages/Dashboard.js
import React, { useState, useRef, useEffect } from 'react';
import Webcam from 'react-webcam';
import { FaCamera, FaTimes, FaCloudUploadAlt } from 'react-icons/fa';
import { useDropzone } from 'react-dropzone';
import { toast, ToastContainer } from 'react-toastify'; // For toast notifications
import 'react-toastify/dist/ReactToastify.css'; // Ensure CSS is imported
import axios from '../utils/axiosConfig'; // Use the configured Axios instance
import { useNavigate } from 'react-router-dom'; // Import useNavigate


const Dashboard = () => {
  const [images, setImages] = useState([]); // Array to store image objects
  const [showWebcam, setShowWebcam] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const webcamRef = useRef(null);
  const maxImages = 5;
  const navigate = useNavigate(); // Hook for navigation
  const [showPopup, setShowPopup] = useState(false); // For pop-up visibility

   // Display popup when clicking disabled button
   const handleDisabledClick = () => {
    setShowPopup(true);
  };

  // Close popup
  const closePopup = () => {
    setShowPopup(false);
  };

  // Function to fetch images from backend
  const fetchImages = async () => {
    setIsLoading(true);
    try {
      const res = await axios.get('/api/images');
      setImages(res.data.images);
    } catch (err) {
      console.error(err.response?.data);
      toast.error('Failed to fetch images.');
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch user images on component mount
  useEffect(() => {
    fetchImages();
  }, []);

  // Handle file uploads via dropzone
  const onDrop = (acceptedFiles) => {
    if (acceptedFiles.length + images.length > maxImages) {
      toast.error(`You can only upload a maximum of ${maxImages} images.`);
      return;
    }

    const formData = new FormData();
    acceptedFiles.forEach((file) => {
      formData.append('images', file); // Note the field name 'images'
    });

    uploadImages(formData);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/*',
    multiple: true,
    maxFiles: maxImages - images.length,
  });

  // Function to upload images to the backend
  const uploadImages = async (formData) => {
    setIsLoading(true);
    try {
      const res = await axios.post('/api/images/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      // After uploading, fetch images again to get updated list
      await fetchImages();
      toast.success('Images uploaded successfully!');
    } catch (err) {
      console.error(err.response?.data);
      toast.error(err.response?.data?.message || 'Image upload failed.');
    } finally {
      setIsLoading(false);
    }
  };

  // Capture photo from webcam
  const handleCapture = () => {
    if (images.length >= maxImages) {
      toast.error(`You can only upload a maximum of ${maxImages} images.`);
      return;
    }
    setShowWebcam(true);
  };

  const handleTakePhoto = async () => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      if (imageSrc) {
        // Convert base64 to Blob
        const res = await fetch(imageSrc);
        const blob = await res.blob();
        const file = new File([blob], `captured_${Date.now()}.jpg`, { type: 'image/jpeg' });

        const formData = new FormData();
        formData.append('images', file); // Note the field name 'images'

        uploadImages(formData);
        setShowWebcam(false);
      }
    }
  };

  // Remove image
  const handleRemoveImage = async (imageId) => {
    try {
      await axios.delete(`/api/images/${imageId}`);
      // After deletion, fetch images again
      await fetchImages();
      toast.info('Image removed.');
    } catch (err) {
      console.error(err.response?.data);
      toast.error('Failed to remove image.');
    }
  };

  return (
    <>
      <ToastContainer />
      {/* Dashboard Header is handled by ProtectedRoute and DashboardLayout */}

      {/* Main Content */}
      <main className="bg-gray-100 min-h-screen pt-20"> {/* Adjust padding-top to account for fixed header */}
        <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 py-10">
          {/* Instructions */}
          <div className="bg-white p-8 rounded-lg shadow-md mb-8">
            <h2 className="text-3xl font-semibold mb-4 text-center">
              Upload Your Photos
            </h2>
            <p className="text-gray-700 text-center">
              Please provide clear photos of your face. You can upload from your device or take a photo using your webcam. You can upload a maximum of 5 images.
            </p>
          </div>

          {/* Upload Options */}
          <div className="bg-white p-8 rounded-lg shadow-md mb-8">
            <div className="flex flex-col sm:flex-row sm:space-x-6 space-y-6 sm:space-y-0">
              {/* Drag and Drop Upload */}
              <div
                {...getRootProps()}
                className={`flex-1 flex flex-col items-center justify-center px-6 py-8 border-2 border-dashed rounded-md cursor-pointer transition duration-300 ${
                  isDragActive ? 'border-blue-600 bg-gray-100' : 'border-gray-300 bg-white'
                }`}
              >
                <FaCloudUploadAlt className="h-8 w-8 text-gray-400" />
                <span className="mt-2 text-gray-600">
                  {isDragActive
                    ? 'Drop the files here...'
                    : 'Drag & drop images here, or click to select'}
                </span>
                <input {...getInputProps()} />
              </div>

              {/* Take a Photo */}
              <button
                onClick={handleCapture}
                className="flex-1 flex flex-col items-center justify-center px-6 py-8 border-2 border-dashed border-gray-300 rounded-md hover:border-blue-600 transition duration-300 focus:outline-none"
                disabled={isLoading}
              >
                <FaCamera className="h-8 w-8 text-gray-400" />
                <span className="mt-2 text-gray-600">Take a Photo</span>
              </button>
            </div>
          </div>

          {/* Webcam Modal */}
          {showWebcam && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
              <div className="bg-white p-6 rounded-lg shadow-lg max-w-lg w-full relative">
                {/* Close Button */}
                <button
                  onClick={() => setShowWebcam(false)}
                  className="absolute top-4 right-4 text-gray-600 hover:text-gray-800 focus:outline-none text-2xl"
                  aria-label="Close Webcam"
                >
                  <FaTimes />
                </button>
                <h3 className="text-xl font-semibold mb-4 text-center">Capture Photo</h3>
                <Webcam
                  audio={false}
                  height={400}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  width={400}
                  className="rounded-md mx-auto"
                />
                <div className="flex justify-center mt-6 space-x-4">
                  <button
                    onClick={handleTakePhoto}
                    className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition duration-300"
                    disabled={isLoading}
                  >
                    Capture
                  </button>
                  <button
                    onClick={() => setShowWebcam(false)}
                    className="bg-gray-600 text-white px-4 py-2 rounded-md hover:bg-gray-700 transition duration-300"
                  >
                    Cancel
                  </button>
                </div>
                {isLoading && (
                  <div className="absolute inset-0 bg-black bg-opacity-25 flex items-center justify-center">
                    <svg className="animate-spin h-10 w-10 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                      ></path>
                    </svg>
                  </div>
                )}
              </div>
            </div>
          )}

          {/* Find Images Button */}
          <div className="text-center mb-6">
            <button
              onClick={images.length > 0 ? () => navigate('/dashboard/my-photos') : handleDisabledClick}
              disabled={images.length === 0}
              className={`px-6 py-3 rounded-lg ${
                images.length > 0
                  ? 'bg-brand-600 text-white hover:bg-brand-700'
                  : 'bg-gray-300 text-gray-500 cursor-not-allowed'
              }`}
            >
              Find Images
            </button>
          </div>

          {/* Popup for Disabled Button */}
          {showPopup && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
              <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full relative">
                <button
                  onClick={closePopup}
                  className="absolute top-4 right-4 text-gray-600 hover:text-gray-800 focus:outline-none text-2xl"
                  aria-label="Close"
                >
                  <FaTimes />
                </button>
                <h3 className="text-xl font-semibold mb-4 text-center">Upload Required</h3>
                <p className="text-gray-700 text-center mb-6">
                  Please upload your photos or selfies to use the "Find Images" feature.
                </p>
                <button
                  onClick={closePopup}
                  className="bg-brand-600 text-white px-4 py-2 rounded-lg hover:bg-brand-700 mx-auto block"
                >
                  Got it
                </button>
              </div>
            </div>
          )}


          {/* Display Uploaded Images */}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {images.map((img) => (
              <div
                key={img.id}
                className="relative group border-2 border-dashed border-gray-300 rounded-md overflow-hidden transition transform hover:scale-105"
              >
                {/* Change this URL */}
                <img
                  src={`https://backpwa.icreativeco.com/${img.image_path}`}
                  alt={`Uploaded ${img.id}`}
                  className="object-cover w-full h-60"
                />
                <button
                  onClick={() => handleRemoveImage(img.id)}
                  className="absolute top-2 right-2 bg-red-600 text-white rounded-full p-1 hover:bg-red-700 transition duration-300"
                  aria-label="Remove Image"
                >
                  <FaTimes className="h-4 w-4" />
                </button>
              </div>
            ))}

            {/* Empty Boxes */}
            {images.length < maxImages &&
              Array.from({ length: maxImages - images.length }).map((_, idx) => (
                <div
                  key={`empty-${idx}`}
                  className="flex items-center justify-center border-2 border-dashed border-gray-300 rounded-md h-60 bg-gray-50 hover:bg-gray-100 transition duration-300"
                >
                  <span className="text-gray-400">No Image</span>
                </div>
              ))}
          </div>
        </div>
      </main>
    </>
  );
};

export default Dashboard;
