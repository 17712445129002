// frontend/src/pages/GetStarted.js
import React, { useState, useEffect, useContext } from "react";
import axios from "../utils/axiosConfig";
import { useNavigate } from "react-router-dom";
import Logo from "../assets/images/logo-dark.png";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import FocusLock from "react-focus-lock";
import { AuthContext } from "../context/AuthContext"; // Import AuthContext

// Define specific Pakistani family relations outside the component
const pakistaniRelations = [
  "Chachu",
  "Mamu",
  "Mami",
  "Chachi",
  "Mama",
  "Bua",
  "Phuphi",
  "Dada",
  "Nana",
  "Bibi",
  "Saali",
  "Saas",
  "Sasur",
  "Nani",
  "Sajan",
  "Maasi",
  "Phuppi",
  "Dadi",
  "Maaji",
  "Sas",
  "Sasu",
  "Maan",
  "Bachha",
  "Beti",
  "Beta",
  "Baba",
  "Ammi",
  "Abbu",
];

const relationsList = ["Friend", "Family", "Colleague", "Other"];

const GetStarted = () => {
  const navigate = useNavigate(); // Hook for navigation
  const { user, loading } = useContext(AuthContext); // Access user from AuthContext

  // State to control modal visibility
  const [activeForm, setActiveForm] = useState(null); // 'register' or 'login'

  useEffect(() => {
    if (!loading && user) {
      // If not loading and user exists, redirect to dashboard
      navigate("/dashboard");
    }
  }, [user, loading, navigate]);

  // State variables for Register Form
  const [registerMessage, setRegisterMessage] = useState("");
  const [country, setCountry] = useState("pk"); // Default country
  const [phone, setPhone] = useState("");
  const [side, setSide] = useState(""); // 'groom' or 'bride'
  const [relation, setRelation] = useState("");
  const [specificRelation, setSpecificRelation] = useState("");
  const [animatedNameIndex, setAnimatedNameIndex] = useState(0);
  const [registerLoading, setRegisterLoading] = useState(false); // Loading state

  // State variables for Login Form
  const [loginMessage, setLoginMessage] = useState("");
  const [loginLoading, setLoginLoading] = useState(false); // Loading state
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Handler functions
// frontend/src/pages/GetStarted.js

const handleRegisterSubmit = async (e) => {
  e.preventDefault();
  console.log("handleRegisterSubmit called"); // Debugging Log
  setIsSubmitting(true);
  setRegisterLoading(true);
  setRegisterMessage("");

  const { username, email, password } = e.target.elements;

  const registerData = {
    username: username.value,
    email: email.value,
    password: password.value,
    phone,
    side,
    relation,
    specificRelation: relation === "Family" ? specificRelation : null,
  };

  try {
    const res = await axios.post("/api/auth/register", registerData, {
      withCredentials: true, // Ensure cookies are included
    });
    setRegisterMessage("Registration successful!");
    navigate("/dashboard"); // Redirect to Dashboard
  } catch (err) {
    console.error(err.response?.data);
    if (err.response?.data?.errors) {
      // Display validation errors
      setRegisterMessage(
        err.response.data.errors.map((error) => error.msg).join(", ")
      );
    } else if (err.response?.data?.message) {
      // Display server messages
      setRegisterMessage(err.response.data.message);
    } else {
      setRegisterMessage("Registration failed");
    }
  } finally {
    setIsSubmitting(false);
    setRegisterLoading(false);
  }
};


  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    setLoginLoading(true);
    setLoginMessage("");

    const { email, password } = e.target.elements;

    const loginData = {
      email: email.value,
      password: password.value,
    };

    try {
      const res = await axios.post("/api/auth/login", loginData, {
        withCredentials: true, // Ensure cookies are included
      });
      setLoginMessage("Login successful!");
      navigate("/dashboard"); // Redirect to Dashboard
    } catch (err) {
      console.error(err.response?.data);
      if (err.response?.data?.errors) {
        // Display validation errors
        setLoginMessage(
          err.response.data.errors.map((error) => error.msg).join(", ")
        );
      } else if (err.response?.data?.message) {
        // Display server messages
        setLoginMessage(err.response.data.message);
      } else {
        setLoginMessage("Login failed");
      }
    } finally {
      setLoginLoading(false);
    }
  };

  const handleSideChange = (e) => {
    setSide(e.target.value);
    setRelation(""); // Reset relation when side changes
    setSpecificRelation(""); // Reset specific relation when side changes
  };

  const handleRelationChange = (e) => {
    setRelation(e.target.value);
    setSpecificRelation(""); // Reset specific relation when relation changes
  };

  const handleSpecificRelationChange = (e) => {
    setSpecificRelation(e.target.value);
  };

  // Animation for relation names (cycling names every 3 seconds)
  useEffect(() => {
    if (!relation) return; // Do not set interval if relation is not selected

    const interval = setInterval(() => {
      setAnimatedNameIndex((prevIndex) =>
        relation === "Family"
          ? (prevIndex + 1) % pakistaniRelations.length
          : (prevIndex + 1) % relationsList.length
      );
    }, 3000); // Change every 3 seconds

    return () => clearInterval(interval);
  }, [relation]); // Depend on 'relation' to handle different list lengths

  // Function to open a specific form
  const openForm = (formType) => {
    setActiveForm(formType);
    // Reset any previous messages
    setRegisterMessage("");
    setLoginMessage("");
  };

  // Function to close any open form
  const closeForm = () => {
    setActiveForm(null);
    // Reset form-related state variables if necessary
    setSide("");
    setRelation("");
    setSpecificRelation("");
    setPhone("");
    setRegisterMessage("");
    setLoginMessage("");
  };

  return (
    <section
      className="relative flex items-center justify-center min-h-screen bg-cover bg-center"
      style={{ backgroundImage: `url('/background.jpg')` }} // Ensure the path to your background image is correct
    >
      {/* Dark Overlay */}
      <div className="absolute inset-0 bg-black bg-opacity-60"></div>

      {/* Content Container */}
      <div className="relative z-10 max-w-md w-full bg-white bg-opacity-90 backdrop-blur-md p-8 rounded-lg shadow-lg">
        {/* Logo */}
        <img
          src={Logo}
          alt="Urooza Logo"
          className="h-12 md:h-16 w-auto mb-6 mx-auto"
        />

        {/* Welcome Message */}
        <h2 className="text-2xl md:text-3xl font-bold text-center mb-4">
          Get Started with Urooza
        </h2>
        <p className="text-center text-gray-700 mb-6">
          Join us today and find all your photos in one place!
        </p>

        {/* Action Buttons */}
        <div className="flex flex-col space-y-4">
          <button
            onClick={() => openForm("register")}
            className="bg-brand-600 text-white font-semibold py-3 px-6 rounded-full shadow-lg hover:bg-brand-700 transition duration-300"
          >
            Register
          </button>
          <button
            onClick={() => openForm("login")}
            className="bg-gray-600 text-white font-semibold py-3 px-6 rounded-full shadow-lg hover:bg-gray-700 transition duration-300"
          >
            Login
          </button>
        </div>
      </div>

      {/* Register Modal */}
      {activeForm === "register" && (
        <FocusLock>
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white bg-opacity-90 backdrop-blur-md p-6 rounded-lg shadow-lg w-full max-w-2xl sm:max-w-lg md:max-w-3xl relative overflow-y-auto max-h-full">
              {/* Close Button */}
              <button
                onClick={closeForm}
                className="absolute top-4 right-4 text-gray-600 hover:text-gray-800 focus:outline-none text-2xl"
                aria-label="Close Register Form"
              >
                &times;
              </button>
              <h3 className="text-2xl font-semibold mb-4 text-center">
                Register
              </h3>
              {registerMessage && (
                <p
                  className={`mb-4 text-center ${
                    registerMessage.includes("successful")
                      ? "text-green-500"
                      : "text-red-500"
                  }`}
                >
                  {registerMessage}
                </p>
              )}
              <form onSubmit={handleRegisterSubmit}>
                {/* Username Field */}
                <div className="mb-4">
                  <label
                    className="block text-gray-700 mb-2"
                    htmlFor="register-username"
                  >
                    Username
                  </label>
                  <input
                    type="text"
                    id="register-username"
                    name="username"
                    className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-brand-600"
                    placeholder="Your Username"
                    required
                  />
                </div>

                {/* Email Field */}
                <div className="mb-4">
                  <label
                    className="block text-gray-700 mb-2"
                    htmlFor="register-email"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    id="register-email"
                    name="email"
                    className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-brand-600"
                    placeholder="you@example.com"
                    required
                  />
                </div>

                {/* Password Field */}
                <div className="mb-4">
                  <label
                    className="block text-gray-700 mb-2"
                    htmlFor="register-password"
                  >
                    Password
                  </label>
                  <input
                    type="password"
                    id="register-password"
                    name="password"
                    className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-brand-600"
                    placeholder="********"
                    required
                  />
                </div>

                {/* Phone Number Field */}
                <div className="mb-4">
                  <label
                    className="block text-gray-700 mb-2"
                    htmlFor="register-phone"
                  >
                    Phone Number
                  </label>
                  <PhoneInput
                    country={country || "us"}
                    value={phone}
                    onChange={(phone) => setPhone(phone)}
                    inputProps={{
                      name: "phone",
                      required: true,
                      autoFocus: false,
                    }}
                    enableSearch
                    inputStyle={{ width: "100%" }}
                    preferredCountries={["pk", "us", "gb", "ca", "au"]} // Added 'pk' for Pakistan
                  />
                </div>

                {/* Side Selection */}
                <div className="mb-4">
                  <label className="block text-gray-700 mb-2">
                    Are you from the?
                  </label>
                  <div className="flex items-center space-x-4">
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="side"
                        value="groom"
                        checked={side === "groom"}
                        onChange={handleSideChange}
                        className="form-radio h-5 w-5 text-brand-600"
                        required
                      />
                      <span className="ml-2 text-gray-700">Groom's Side</span>
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="side"
                        value="bride"
                        checked={side === "bride"}
                        onChange={handleSideChange}
                        className="form-radio h-5 w-5 text-brand-600"
                        required
                      />
                      <span className="ml-2 text-gray-700">Bride's Side</span>
                    </label>
                  </div>
                </div>

                {/* Conditional Relation Field */}
                {side && (
                  <div className="mb-4">
                    <label className="block text-gray-700 mb-2">
                      Relation with {side === "groom" ? "Groom" : "Bride"}
                    </label>
                    <select
                      value={relation}
                      onChange={handleRelationChange}
                      className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-brand-600"
                      required
                    >
                      <option value="">Select Relation</option>
                      {relationsList.map((rel, index) => (
                        <option key={index} value={rel}>
                          {rel}
                        </option>
                      ))}
                    </select>
                  </div>
                )}

                {/* Conditional Specific Relation Field */}
                {relation === "Family" && (
                  <div className="mb-4">
                    <label className="block text-gray-700 mb-2">
                      Specific Relation with{" "}
                      {side === "groom" ? "Groom" : "Bride"}
                    </label>
                    <select
                      value={specificRelation}
                      onChange={handleSpecificRelationChange}
                      className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-brand-600"
                      required
                    >
                      <option value="">Select Specific Relation</option>
                      {pakistaniRelations.map((rel, index) => (
                        <option key={index} value={rel}>
                          {rel}
                        </option>
                      ))}
                    </select>
                  </div>
                )}

                {/* Animated Relation Names */}
                {relation && (
                  <div className="mb-4">
                    <p className="text-gray-700 mb-2">Popular Relations:</p>
                    <div className="h-8 overflow-hidden relative">
                      <span className="absolute w-full text-brand-600 text-lg text-center transition-opacity duration-1000">
                        {relation === "Family"
                          ? pakistaniRelations[animatedNameIndex]
                          : relationsList[animatedNameIndex]}
                      </span>
                    </div>
                  </div>
                )}

                {/* Submit Button */}
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className={`mt-6 w-full ${
                    isSubmitting
                      ? "bg-gray-400 cursor-not-allowed"
                      : "bg-brand-600 hover:bg-brand-700"
                  } text-white py-2 rounded`}
                >
                  {isSubmitting ? "Registering..." : "Save Changes"}
                </button>
              </form>
            </div>
          </div>
        </FocusLock>
      )}

      {/* Login Modal */}
      {activeForm === "login" && (
        <FocusLock>
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white bg-opacity-90 backdrop-blur-md p-6 rounded-lg shadow-lg w-full max-w-2xl sm:max-w-lg md:max-w-3xl relative overflow-y-auto max-h-full">
              {/* Close Button */}
              <button
                onClick={closeForm}
                className="absolute top-4 right-4 text-gray-600 hover:text-gray-800 focus:outline-none text-2xl"
                aria-label="Close Login Form"
              >
                &times;
              </button>
              <h3 className="text-2xl font-semibold mb-4 text-center">Login</h3>
              {loginMessage && (
                <p
                  className={`mb-4 text-center ${
                    loginMessage.includes("successful")
                      ? "text-green-500"
                      : "text-red-500"
                  }`}
                >
                  {loginMessage}
                </p>
              )}
              <form onSubmit={handleLoginSubmit}>
                {/* Email Field */}
                <div className="mb-4">
                  <label
                    className="block text-gray-700 mb-2"
                    htmlFor="login-email"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    id="login-email"
                    name="email"
                    className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-brand-600"
                    placeholder="you@example.com"
                    required
                  />
                </div>

                {/* Password Field */}
                <div className="mb-6">
                  <label
                    className="block text-gray-700 mb-2"
                    htmlFor="login-password"
                  >
                    Password
                  </label>
                  <input
                    type="password"
                    id="login-password"
                    name="password"
                    className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-brand-600"
                    placeholder="********"
                    required
                  />
                </div>

                {/* Submit Button */}
                <button
                  type="submit"
                  className={`w-full bg-gray-600 text-white py-2 px-4 rounded-full shadow-lg hover:bg-gray-700 transition duration-300 ${
                    loginLoading ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                  disabled={loginLoading}
                >
                  {loginLoading ? "Logging in..." : "Login"}
                </button>
              </form>
            </div>
          </div>
        </FocusLock>
      )}
    </section>
  );
};

export default GetStarted;
