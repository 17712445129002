// frontend/src/pages/Profile.js
import React, { useState, useEffect } from 'react';
import axios from '../utils/axiosConfig'; // Use configured Axios instance
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaUserCircle } from 'react-icons/fa';

const Profile = () => {
  const [userData, setUserData] = useState({
    username: '',
    email: '',
    phone: '',
    side: '',
    relation: '',
    specificRelation: '',
  });
  const [profileImage, setProfileImage] = useState(null); // For displaying profile image
  const [selectedFile, setSelectedFile] = useState(null); // For storing the file before upload
  const backendBaseURL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    // Fetch existing user data
    const fetchUserData = async () => {
      try {
        const res = await axios.get('/api/auth/user');
        setUserData(res.data.user);
        setProfileImage(res.data.user.profile_image); // Use 'profile_image' instead of 'profileImage'
        console.log('Backend Response User Data:', res.data.user);
        console.log('Environment Base URL:', backendBaseURL);
        console.log('Profile Image Path:', res.data.user.profile_image);
      } catch (err) {
        console.error(err.response?.data);
        toast.error('Failed to fetch user data.');
      }
    };
    fetchUserData();
  }, []);

  // Handle input changes
  const handleChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  // Handle profile image upload
  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    // Optional: Validate file size and type on the client-side
    const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'];
    if (!allowedTypes.includes(file.type)) {
      toast.error('Only JPEG, JPG, PNG, and GIF images are allowed.');
      return;
    }
    if (file.size > 5 * 1024 * 1024) { // 5MB
      toast.error('Image size should not exceed 5MB.');
      return;
    }

    setSelectedFile(file);
    setProfileImage(URL.createObjectURL(file)); // Preview image

    const formData = new FormData();
    formData.append('profileImage', file);
    try {
      const res = await axios.post('/api/profile/upload-profile-image', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      setProfileImage(res.data.profileImagePath); // Display uploaded image preview
      // Update userData to include the new profile_image
      setUserData(prevData => ({
        ...prevData,
        profile_image: res.data.profileImagePath,
      }));
      toast.success('Profile image uploaded successfully');
    } catch (err) {
      console.error(err.response?.data);
      toast.error('Failed to upload profile image');
    }
  };

  // Submit form data to update profile
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Prepare the data to send (exclude profileImage as it's handled separately)
    const updatedData = { ...userData };

    try {
      const res = await axios.put('/api/auth/update-profile', updatedData, {
        headers: { 'Content-Type': 'application/json' },
      });
      setUserData(res.data.user); // Update local state with latest user data
      toast.success('Profile updated successfully');
    } catch (err) {
      console.error(err.response?.data);
      // Handle validation errors if any
      if (err.response?.data?.errors) {
        err.response.data.errors.forEach((error) => {
          toast.error(error.msg);
        });
      } else {
        toast.error('Failed to update profile.');
      }
    }
  };

  return (
    <div className="bg-gray-100 min-h-screen pt-20">
      <ToastContainer />
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 py-10">
        <h2 className="text-3xl font-semibold mb-6 text-center">My Profile</h2>

        <form onSubmit={handleSubmit} className="bg-white p-8 rounded-lg shadow-md">
          {/* Profile Image */}
          <div className="flex flex-col items-center justify-center mb-6">
            {profileImage ? (
              <img
              src={`${process.env.REACT_APP_API_BASE_URL}/${profileImage}?t=${new Date().getTime()}`}

                alt="Profile"
                className="rounded-full h-32 w-32 object-cover mb-4"
              />
            ) : (
              <FaUserCircle className="h-32 w-32 text-gray-300 mb-4" />
            )}
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              className="mt-4"
            />
          </div>

          {/* User Information Form */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label className="block font-medium text-gray-700">Username</label>
              <input
                type="text"
                name="username"
                value={userData.username}
                onChange={handleChange}
                required
                className="mt-1 block w-full border border-gray-300 p-2 rounded"
              />
            </div>
            <div>
              <label className="block font-medium text-gray-700">Email</label>
              <input
                type="email"
                name="email"
                value={userData.email}
                onChange={handleChange}
                required
                className="mt-1 block w-full border border-gray-300 p-2 rounded"
              />
            </div>
            <div>
              <label className="block font-medium text-gray-700">Phone</label>
              <input
                type="text"
                name="phone"
                value={userData.phone}
                onChange={handleChange}
                className="mt-1 block w-full border border-gray-300 p-2 rounded"
              />
            </div>
            <div>
              <label className="block font-medium text-gray-700">Side</label>
              <select
                name="side"
                value={userData.side}
                onChange={handleChange}
                required
                className="mt-1 block w-full border border-gray-300 p-2 rounded"
              >
                <option value="">Select</option>
                <option value="groom">Groom</option>
                <option value="bride">Bride</option>
              </select>
            </div>
            <div>
              <label className="block font-medium text-gray-700">Relation</label>
              <select
                name="relation"
                value={userData.relation}
                onChange={handleChange}
                required
                className="mt-1 block w-full border border-gray-300 p-2 rounded"
              >
                <option value="">Select</option>
                <option value="Friend">Friend</option>
                <option value="Family">Family</option>
                <option value="Colleague">Colleague</option>
                <option value="Other">Other</option>
              </select>
            </div>
            {userData.relation === 'Family' && (
              <div>
                <label className="block font-medium text-gray-700">Specific Relation</label>
                <input
                  type="text"
                  name="specificRelation"
                  value={userData.specificRelation}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full border border-gray-300 p-2 rounded"
                />
              </div>
            )}
          </div>

          <button
            type="submit"
            className="mt-6 w-full bg-brand-600 text-white py-2 rounded hover:bg-brand-700"
          >
            Save Changes
          </button>
        </form>
      </div>
    </div>
  );
};

export default Profile;
