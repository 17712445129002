// frontend/src/context/AuthContext.js
import React, { createContext, useState, useEffect } from 'react';
import axios from '../utils/axiosConfig'; // Use the configured Axios instance
import { useNavigate } from 'react-router-dom'; // Import useNavigate

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate(); // Correctly inside the component

  // Function to fetch user data based on session
  const fetchUser = async () => {
    try {
      const response = await axios.get('/api/auth/user'); // withCredentials is set globally
      setUser(response.data.user);
    } catch (error) {
      console.error('User fetch failed:', error);
      setUser(null); // User is not authenticated
    } finally {
      setLoading(false); // End loading after checking the session
    }
  };

  useEffect(() => {
    fetchUser(); // Fetch user on component mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logoutUser = async () => {
    try {
      await axios.post('/api/auth/logout'); // withCredentials is set globally
      setUser(null); // Update user state
      navigate('/get-started'); // Redirect to Get Started after logout
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  return (
    <AuthContext.Provider value={{ user, setUser, logout: logoutUser, loading }}>
      {children}
    </AuthContext.Provider>
  );
};
