// frontend/src/pages/Home.js
import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../assets/images/logo.png'; // Import the logo

const Home = () => {
  return (
    <section
      className="relative flex items-center justify-center h-screen bg-cover bg-center"
      style={{ backgroundImage: `url('/background.jpg')` }}
    >
      {/* Dark Overlay */}
      <div className="absolute inset-0 bg-black bg-opacity-60"></div>

      {/* Hero Content */}
      <div className="relative z-10 text-center px-4 max-w-2xl">
        <img src={Logo} alt="Urooza Logo" className="w-48 md:w-96 mb-6 mx-auto" />
        <h1 className="text-4xl md:text-6xl font-bold text-white mb-4">Welcome</h1>
        <p className="text-lg md:text-2xl text-gray-200 mb-8">
          Find All Your Photos In One Place!
        </p>
        <Link
          to="/get-started"
          className="inline-block bg-brand-600 text-white font-semibold py-3 px-6 rounded-full shadow-lg hover:bg-brand-700 transition duration-300"
        >
          Get Started
        </Link>
      </div>
    </section>
  );
};

export default Home;
