// frontend/src/components/Navbar.js
import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import Logo from '../assets/images/logo.png'; // Ensure this path is correct
import FocusLock from 'react-focus-lock';

const Navbar = () => {
  const location = useLocation();
  const isTransparentHeader = ['/', '/get-started'].includes(location.pathname);
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);
  const buttonRef = useRef(null);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // Prevent scrolling when menu is open
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isOpen]);

  // Close the menu when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isOpen &&
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  // Close the menu when pressing the Escape key
  useEffect(() => {
    const handleEscape = (event) => {
      if (isOpen && event.key === 'Escape') {
        setIsOpen(false);
      }
    };

    document.addEventListener('keydown', handleEscape);
    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, [isOpen]);

  return (
    <header className="fixed top-0 left-0 w-full z-50">
      <nav
        className={`flex items-center justify-between px-6 py-4 
          ${
            isTransparentHeader
              ? 'bg-white bg-opacity-20 backdrop-blur-lg'
              : 'bg-white bg-opacity-90 backdrop-blur-md'
          } 
          shadow-md transition duration-300`}
      >
        {/* Logo */}
        <div className="logo flex items-center">
          <Link to="/">
            <img src={Logo} alt="Urooza Logo" className="h-10 md:h-14 w-auto" />
          </Link>
        </div>

        {/* Hamburger Menu (Mobile) */}
        <div className="md:hidden">
          <button
            ref={buttonRef}
            onClick={toggleMenu}
            className="text-brand-600 focus:outline-none"
            aria-label={isOpen ? 'Close menu' : 'Open menu'}
            aria-expanded={isOpen}
            aria-controls="mobile-menu"
          >
            {isOpen ? <XIcon className="h-6 w-6" /> : <MenuIcon className="h-6 w-6" />}
          </button>
        </div>

        {/* Navigation Links (Desktop) */}
        <ul className="hidden md:flex space-x-6 items-center">
          <li>
            <Link
              to="/"
              className={`${
                location.pathname === '/' ? 'text-brand-600 border-b-2 border-brand-600' : 'text-white'
              } hover:text-brand-600 transition duration-300`}
            >
              Home
            </Link>
          </li>
          <li>
            <Link
              to="/get-started"
              className="bg-brand-600 text-white py-2 px-4 rounded-full hover:bg-brand-700 transition duration-300 md:py-3 md:px-6"
            >
              Get Started
            </Link>
          </li>
        </ul>
      </nav>

      {/* Mobile Menu Overlay */}
      {isOpen && (
        <div className="fixed inset-0 bg-white bg-opacity-90 backdrop-filter backdrop-blur-md p-6">
          <FocusLock>
            <div
              ref={menuRef}
              id="mobile-menu"
              className="w-full h-full flex flex-col items-start justify-start space-y-6"
            >
              {/* Close Button Inside Mobile Menu */}
              <button
                onClick={toggleMenu}
                className="absolute top-4 right-4 text-gray-800 hover:text-gray-600 focus:outline-none"
                aria-label="Close menu"
              >
                <XIcon className="h-6 w-6" />
              </button>

              {/* Navigation Links */}
              <ul className="flex flex-col space-y-4 mt-8 w-full">
                <li className="w-full">
                  <Link
                    to="/"
                    className="block text-gray-800 text-xl font-semibold hover:text-brand-600 transition duration-300"
                    onClick={() => setIsOpen(false)}
                  >
                    Home
                  </Link>
                </li>
                <li className="w-full">
                  <Link
                    to="/get-started"
                    className="block bg-brand-600 text-white py-3 px-6 rounded-full text-center w-full hover:bg-brand-700 transition duration-300"
                    onClick={() => setIsOpen(false)}
                  >
                    Get Started
                  </Link>
                </li>
              </ul>
            </div>
          </FocusLock>
        </div>
      )}
    </header>
  );
};

export default Navbar;
